const detailPageStyle = (theme) => ({
  detailPageContainer: {
    backgroundColor: theme.palette.background.elitGray,
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    border: '1px solid ' + theme.palette.border,
    padding: 40,
    boxSizing: 'border-box',
  },
  formTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  selectFlexFlowRow: {
    display: 'flex',
    flexFlow: 'row',
  },
  overflowAuto: {
    overflow: 'auto',
  },
  alignSelfFlexStart: {
    alignSelf: 'flex-start',
  },
  alignLeft: {
    display: 'flex',
    justifyContent: 'flex-start !important',
    flexDirection: 'column',
    '& button': {
      paddingLeft: 16,
    },
  },
  removeFile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: '100%',
    width: '100%',
  },
  buttonAndTable: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',

    '& table': {
      minWidth: 'calc(100% - 116px)',
      marginTop: 18,
    },

    '& button': {
      minWidth: 100,
      marginLeft: 16,
      marginTop: 4,
    },
  },
  lastRowRoundBorder: {
    '& tbody': {
      '& tr:last-child': {
        '& td.MuiTableCell-body': {
          borderBottom: '1px solid ' + theme.palette.border,
          boxSizing: 'border-box',
          '&:first-child': {
            borderLeft: '1px solid ' + theme.palette.border,
            borderBottomLeftRadius: theme.shape.borderRadius,
          },
          '&:last-child': {
            borderBottomRightRadius: theme.shape.borderRadius,
            borderRight: '1px solid ' + theme.palette.border,
          },
        },
      },
    },
  },
  detailTable: {
    '& tbody tr td': {
      backgroundColor: theme.palette.background.white,
    },
  },
  attachmentTablePadding: {
    '&:nth-child(1)': {
      paddingRight: 8,
    },
    '&:nth-child(2)': {
      paddingLeft: 8,
    },
  },
  downloadLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },

  fleetMapContainer: {
    maxHeight: '100%',
    width: 'auto',
  },
  fleetDetail: {
    fontSize: 13,
    color: theme.palette.text.primary,
    marginBottom: 6,
    marginRight: 40,
    borderBottom: '1px solid ' + theme.palette.border,
  },
  fleetDetailLabel: {
    fontWeight: 700,
  },
  fleetDetailText: {},
  statusDescription: {
    height: 150,
    boxSizing: 'border-box',
    display: 'flex',
    alignContent: 'flex-start',
  },
  statusControl: {
    height: 150,
    boxSizing: 'border-box',
    display: 'flex',
    alignContent: 'flex-start',
  },
  tableHeaderGrid: {
    height: 32,
    fontSize: 12,
    boxSizing: 'border-box',
    fontWeight: 600,
    lineHeight: 1.1,
    paddingBottom: 2,
    color: theme.palette.text.secondary,
  },
  paddingBottomRow: {
    paddingBottom: 8,
  },
  addItemButton: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px dashed ' + theme.palette.border,
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    margin: 4,
    color: theme.palette.text.disabled,
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    transition: 'color 200ms, border 200ms',
    height: 30,

    '&:hover': {
      color: theme.palette.text.secondary,
      border: '2px dashed ' + theme.palette.text.secondary,
      transition: 'color 200ms, border 200ms',
    },
  },
  removeItemButton: {
    margin: '0px 0px 0px 8px',
    padding: 0,
    border: '1px solid rgba(0, 0, 0, 0.26)',
    borderRadius: theme.shape.borderRadius,
    height: 30,
    width: 30,
    backgroundColor: theme.palette.background.white,

    '&:hover': {
      border: '1px solid ' + theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
  },
  staticField: {
    backgroundColor: theme.palette.background.white,
    height: 30,
    padding: '4px 8px',
    fontSize: 12,
    boxSizing: 'border-box',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    letterSpacing: '0.00938em',
    boxShadow: '0 2px 6px 0 rgb(103 103 139 / 10%)',
    '&:hover': {
      border: '1px solid ' + theme.palette.text.primary,
    },
  },
  staticFieldDisabled: {
    backgroundColor: theme.palette.background.white,
    height: 30,
    padding: '4px 8px',
    fontSize: 12,
    boxSizing: 'border-box',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    letterSpacing: '0.00938em',
    boxShadow: '0 2px 6px 0 rgb(103 103 139 / 10%)',
    cursor: 'not-allowed',
    color: '#585b6cee',
  },
  staticFieldLabel: {
    color: '#585b6c',
    height: 18,
    fontSize: 12,
    boxSizing: 'border-box',
    fontWeight: 600,
    lineHeight: 1.1,
    paddingBottom: 2,
  },
  staticFieldBottomPadding: {
    marginBottom: 16.4,
  },
  textAlignEnd: {
    justifyContent: 'flex-end',
  },
  statusDescriptionText: {
    fontSize: 14,
  },
  inLineInput: {},
  tooltipLabel: {
    borderBottom: '1px dotted gray',
    cursor: 'help',
  },
})

export default detailPageStyle
