import React, {useState} from 'react'
import Box from '@material-ui/core/Box'
import {Field, Form} from 'react-final-form'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import {Trans} from '@lingui/macro'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import fieldStyle from 'component/field/fieldStyle'
import {connect} from 'react-redux'
import {forgottenPassword} from 'redux/action/authAction'
import {fireErrorToast, fireSuccessToast} from 'helper/functions'
import TextInput from 'component/field/TextInput'
import {required} from 'helper/validations'
import BoxFullWidth from 'component/material/BoxFullWidth'
import {INVOICES_RESET_PSW_URL} from 'helper/constants'

const ResetPasswordForm = (props) => {
  const {classes, handleCloseResetPassword, forgottenPassword} = props

  const [resetLoading, setResetLoading] = useState(false)

  const handleSubmit = (values) => {
    setResetLoading(true)
    forgottenPassword({...values, reset_password_url: INVOICES_RESET_PSW_URL})
      .then((res) => {
        fireSuccessToast(<Trans>Link to reset password was send.</Trans>)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Link to reset password failed to send.</Trans>)
      })
      .finally(() => {
        handleCloseResetPassword()
        setResetLoading(false)
      })
  }

  return (
    <Box p={5}>
      <Form onSubmit={handleSubmit}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer>
                <GridItem container alignItems={'center'}>
                  <GridItem xs={12} sm={8}>
                    <Box pr={2.5}>
                      <Field
                        name="email"
                        label={<Trans>Your e-mail</Trans>}
                        component={TextInput}
                        validate={required}
                        autoComplete="email"
                        className={classes.loginPageInput}
                        filters={true}
                      />
                    </Box>
                  </GridItem>
                  <GridItem xs={12} sm={4} className={classes.submitFormButtonPosition}>
                    <BoxFullWidth pl={2.5}>
                      <PrimaryButton
                        disabled={resetLoading}
                        size={'large'}
                        type="submit"
                        fullWidth={true}
                        textPadding={0}
                        text={<Trans>Send link</Trans>}
                        className={classes.loginButton}
                      />
                    </BoxFullWidth>
                  </GridItem>
                </GridItem>
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </Box>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      forgottenPassword,
    },
    dispatch
  )
}

export default compose(withStyles(fieldStyle), connect(null, mapDispatchToProps))(ResetPasswordForm)
