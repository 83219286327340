import React, {useState} from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextInput from 'component/field/TextInput'
import fieldStyle from 'component/field/fieldStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import {ReactComponent as Password} from 'style/asset/old/password.svg'
import cx from 'classnames'
import {Visibility, VisibilityOff} from '@material-ui/icons'

/**
 * Simple password component with visible toggle icon
 */
const PasswordInput = (props) => {
  const {
    classes,
    input,
    passwordEndAdornment = true,
    passwordStartAdornment = true,
    loginPage = false,
    ...rest
  } = props

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = (event) => {
    event.preventDefault()
    setShowPassword((showPassword) => !showPassword)
  }

  const AdornmentIcon = showPassword ? VisibilityOff : Visibility
  const inputElementType = showPassword ? 'text' : 'password'

  return (
    <TextInput
      variant={'outlined'}
      {...input}
      type={inputElementType}
      InputProps={{
        startAdornment: passwordStartAdornment && (
          <InputAdornment position={'start'}>
            <div
              className={cx(
                classes.adornmentIcon,
                classes.grayBackground,
                loginPage && classes.loginPageAdornment
              )}
            >
              <Password />
            </div>
          </InputAdornment>
        ),
        endAdornment: passwordEndAdornment && (
          <InputAdornment className={classes.adornment} position={'end'}>
            <div
              className={cx(
                classes.adornmentIcon,
                classes.cursorPointer,
                loginPage && classes.loginPageAdornment
              )}
              onClick={handleClickShowPassword}
            >
              <AdornmentIcon />
            </div>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  )
}

PasswordInput.propTypes = {
  classes: PropTypes.object,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  rest: PropTypes.object,
}

export default withStyles(fieldStyle)(PasswordInput)
