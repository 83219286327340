import React from 'react'
import {Trans} from '@lingui/macro'
import moment from 'moment'
import {DATE_FORMAT} from 'helper/constants'

/// return undefined == no error for the react-final-form ///

// connect multiple validations together,
// otherwise you can omit this function and use single validation on Field
export const composeValidators =
  (...validators) =>
  (value) => {
    return validators.reduce((error, validator) => {
      return error || validator(value)
    }, undefined)
  }

//  FIELD LEVEL VALIDATIONS
export const required = (value) => {
  return value ? undefined : <Trans>Required field</Trans>
}

export const validateEmail = (value) => {
  if (value && !value?.includes('@')) {
    return <Trans>Wrong email address</Trans>
  }
  return undefined
}

export const maxDate = (maxDate) => (value) => {
  if (moment(maxDate, DATE_FORMAT) < moment(value, DATE_FORMAT)) {
    return (
      <Trans>Date cannot be higher than {moment(maxDate, DATE_FORMAT).format(DATE_FORMAT)}</Trans>
    )
  }
  return undefined
}

export const minDate = (minDate) => (value) => {
  if (moment(minDate, DATE_FORMAT) > moment(value, DATE_FORMAT)) {
    return (
      <Trans>Date cannot be lower than {moment(minDate, DATE_FORMAT).format(DATE_FORMAT)}</Trans>
    )
  }
  return undefined
}

// FORM LEVEL VALIDATIONS
export const samePasswordValidation = (values) => {
  if (values.new_password !== values.password_confirmation) {
    return {password_confirmation: <Trans>Passwords must be the same</Trans>}
  }
  return undefined
}

export const validateVin = (value) => {
  if (value?.length !== 17) {
    return <Trans>VIN must be 17 characters long</Trans>
  } else if (value.includes('I') || value.includes('O') || value.includes('Q')) {
    return <Trans>Value is not valid VIN</Trans>
  }
  return undefined
}
