import {getServiceUrl} from 'helper/functions'

// map of endpoints for cleaner use in api calls
const endpoints = {
  login: `${getServiceUrl('auth')}/login`,
  logout: `${getServiceUrl('auth')}/logout`,
  forgottenPassword: `${getServiceUrl('auth')}/forgotten-password`,
  resetPassword: `${getServiceUrl('auth')}/reset-password`,
  currentUser: `${getServiceUrl('auth')}/current-user`,

  // invoices
  invoices: `${getServiceUrl('cfs-invoices')}/invoices`,
  attachments: `${getServiceUrl('cfs-invoices')}/attachment-types`,
  cars: `${getServiceUrl('cfs-invoices')}/cars`,
  mySupplier: `${getServiceUrl('cfs-invoices')}/my-supplier`,
  myFleets: `${getServiceUrl('cfs-invoices')}/my-fleets`,
  fleets: `${getServiceUrl('cfs-invoices')}/fleets`,
}

export default endpoints
