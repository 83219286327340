import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import fieldStyle from 'component/field/fieldStyle'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import {ReactComponent as IcoDown} from 'style/asset/old/ico-down.svg'
import cx from 'classnames'

const SelectInput = (props) => {
  const {
    classes,
    disabled,
    label,
    customName,
    input,
    value,
    onChange,
    meta,
    options,
    helperText,
    showHelperText = true,
    variant = 'outlined',
    loading,
    emptyOption = false,
    customError,
    fullWidth = true,
    inLineInput,
    ...rest
  } = props

  const errorMessage = customError || (meta && meta.touched && (meta.error || meta.submitError))
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')
  return (
    <>
      {label && (
        <div className={cx(classes.fieldLabel, inLineInput && classes.inLineFieldLabel)}>
          {label}
        </div>
      )}
      <FormControl
        error={!!errorMessage}
        variant={variant}
        className={cx(
          !inLineInput && classes.formControl,
          inLineInput && classes.inLineFlexGrow,
          classes.selectPositionRelativeClass
        )}
      >
        <Select
          {...input}
          {...rest}
          value={input ? input.value : value}
          name={input ? input.name : customName}
          onChange={(e) => {
            input?.onChange(e)
            onChange && onChange(e)
          }}
          disabled={disabled}
          className={cx(classes.selectInput, classes.inputStyles)}
          IconComponent={IcoDown}
          endAdornment={
            loading && (
              <InputAdornment position="end" className={classes.loadingEndAdornment}>
                <CircularProgress color="primary" />
              </InputAdornment>
            )
          }
        >
          {!loading && options?.length !== 0 && emptyOption && (
            <MenuItem value={null} className={cx(classes.noDataText, classes.selectMenuItem)}>
              <em>
                <Trans>None</Trans>
              </em>
            </MenuItem>
          )}

          {options?.map((option, index) => (
            // if option is object with value and label, use that
            // otherwise use option as string
            <MenuItem
              className={classes.selectMenuItem}
              key={index}
              value={
                option.value
                  ? option.value
                  : option.id
                  ? option.id
                  : option.ref
                  ? option.ref
                  : option
              }
            >
              {option.label ? option.label : option.name ? option.name : option}
            </MenuItem>
          ))}

          {!loading && options?.length === 0 && (
            <MenuItem value={null} className={cx(classes.noDataText, classes.selectMenuItem)}>
              <em>
                <Trans>No data</Trans>
              </em>
            </MenuItem>
          )}
        </Select>
        {showHelperText && (
          <FormHelperText className={classes.formHelperText}>{errorHelperText}</FormHelperText>
        )}
      </FormControl>
    </>
  )
}

SelectInput.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.object,
  input: PropTypes.object,
  customName: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.array,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  showHelperText: PropTypes.bool,
  helperText: PropTypes.string,
}

export default withStyles(fieldStyle)(SelectInput)
