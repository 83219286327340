import React, {useEffect, useState} from 'react'
import {redirectTo} from 'helper/functions'
import {Trans} from '@lingui/macro'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import componentStyle from 'component/componentStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {getCfsList} from 'redux/action/cfsAction'
import BoxFullWidth from 'component/material/BoxFullWidth'
import PrimaryButton from 'component/material/PrimaryButton'
import Table from 'component/material/table/Table'
import TextInput from 'component/field/TextInput'
import {ReactComponent as Edit} from 'style/asset/edit.svg'
import CustomTooltip from 'component/material/CustomTooltip'

let intervalId = null
let searchInterval = null

const CFSPage = (props) => {
  const {classes, rowsPerPage, getCfsList, cfsList, cfsListLoading, cfsListMeta} = props

  const [tableSort, setTableSort] = useState({orderBy: 'date_issue', orderDirection: 'DESC'})
  const [page, setPage] = useState(0)
  const [searchValue, setSearchValue] = useState(null)

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleTableSort = (sort) => {
    setTableSort(sort)
  }

  const openDetail = (row) => (e) => {
    redirectTo('/invoice/' + row.id)
  }

  const columns = [
    {
      name: 'customer_name',
      sortKey: 'customer_name',
      label: <Trans>Customer name</Trans>,
    },
    {
      name: 'order_no',
      sortKey: 'order_no',
      label: <Trans>Order number</Trans>,
    },
    {
      name: 'tax_document_no',
      sortKey: 'tax_document_no',
      label: <Trans>Tax document number</Trans>,
    },
    {
      name: 'car',
      sortKey: 'car__model_desc',
      label: <Trans>Car</Trans>,
      render: (val) => (val ? val.model_desc : null),
    },
    {
      name: 'car',
      sortKey: 'car__rz',
      label: <Trans>RZ</Trans>,
      render: (val) => (val ? val.rz : null),
    },
    {
      name: 'date_issue',
      sortKey: 'date_issue',
      align: 'right',
      label: <Trans>Date issue</Trans>,
    },
    {
      name: 'status',
      sortKey: 'status__id',
      label: <Trans>Status</Trans>,
      render: (val) => (val ? val.description_cz : null),
    },
    {
      name: '',
      label: '',
      align: 'right',
      width: '40px',
      render: (val, row) => (
        <div className={classes.editIcon} onClick={openDetail(row)}>
          <Edit />
        </div>
      ),
    },
  ]

  const openNew = () => {
    redirectTo('/invoice/new')
  }

  const debounceSearch = (value) => {
    clearTimeout(searchInterval)
    searchInterval = setTimeout(() => {
      setPage(0)
      setSearchValue(value)
    }, 500)
  }

  const handleSearchChange = (e) => {
    debounceSearch(e.target.value)
  }

  useEffect(() => {
    getCfsList(rowsPerPage, page * rowsPerPage, tableSort, searchValue)

    return () => {
      clearTimeout(intervalId)
      clearTimeout(searchInterval)
    }
  }, [page, rowsPerPage, tableSort, searchValue])

  return (
    <BoxFullWidth>
      <div className={classes.listPageControlContainer}>
        <div className={classes.searchContainer}>
          <div className={classes.searchLabel}>
            <Trans>Search:</Trans>
          </div>
          <TextInput
            onChange={handleSearchChange}
            loading={cfsListLoading}
            className={classes.searchInput}
            showHelperText={false}
          />
        </div>
        <div>
          <CustomTooltip title={<Trans>New invoice</Trans>}>
            <PrimaryButton
              fullWidth={false}
              textPadding={0}
              text={<Trans>New</Trans>}
              onClick={openNew}
            />
          </CustomTooltip>
        </div>
      </div>
      <div className={classes.overFlowAuto}>
        <Table
          data={cfsList}
          columns={columns}
          loading={cfsListLoading}
          onTableColumnSort={handleTableSort}
          tableSort={tableSort}
          count={cfsListMeta.total_count || cfsListMeta.count}
          page={page}
          meta={cfsListMeta}
          onChangePage={handleChangePage}
        />
      </div>
    </BoxFullWidth>
  )
}

CFSPage.propTypes = {
  classes: PropTypes.object,
  rowsPerPage: PropTypes.number,
  getCfsList: PropTypes.func,
  cfsList: PropTypes.array,
  cfsListLoading: PropTypes.bool,
  cfsListMeta: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCfsList,
    },
    dispatch
  )
}

export default compose(
  withStyles(componentStyle),
  connect((store) => {
    return {
      rowsPerPage: store.globalSettings.rowsPerPage,
      cfsList: store.cfs.cfsList,
      cfsListLoading: store.cfs.cfsListLoading,
      cfsListMeta: store.cfs.cfsListMeta,
    }
  }, mapDispatchToProps)
)(CFSPage)
