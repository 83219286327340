import {
  GET_ATTACHMENTS_FAIL,
  GET_ATTACHMENTS_REQUEST,
  GET_ATTACHMENTS_SUCCESS,
  GET_CARS_FAIL,
  GET_CARS_REQUEST,
  GET_CARS_SUCCESS,
  GET_CFS_FAIL,
  GET_CFS_LIST_FAIL,
  GET_CFS_LIST_REQUEST,
  GET_CFS_LIST_SUCCESS,
  GET_CFS_REQUEST,
  GET_CFS_SUCCESS,
  GET_MY_FLEETS_FAIL,
  GET_MY_FLEETS_REQUEST,
  GET_MY_FLEETS_SUCCESS,
  GET_MY_SUPPLIERS_FAIL,
  GET_MY_SUPPLIERS_REQUEST,
  GET_MY_SUPPLIERS_SUCCESS,
  GET_PREDEFINED_TERMS_FAIL,
  GET_PREDEFINED_TERMS_REQUEST,
  GET_PREDEFINED_TERMS_SUCCESS,
} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import React from 'react'
import {Trans} from '@lingui/macro'

export const getCfsList = (limit = 1000, offset = 0, meta = {}, search) => {
  return (dispatch) => {
    dispatch({type: GET_CFS_LIST_REQUEST})
    const searchQuery = search ? `?search=${search}` : ''
    return httpClient
      .get(endpoints.invoices + searchQuery, {
        limit: limit,
        offset: offset,
        order_by:
          meta?.orderBy && meta?.orderDirection && `${meta?.orderBy}:${meta?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_CFS_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CFS_LIST_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getCfs = (id) => {
  return (dispatch) => {
    dispatch({type: GET_CFS_REQUEST})
    return httpClient
      .get(endpoints.invoices + '/' + id)
      .then((res) => {
        dispatch({type: GET_CFS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CFS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const createCfs = (data) => {
  return (dispatch) => {
    return httpClient
      .post(endpoints.invoices, data)
      .then((res) => {
        fireSuccessToast(<Trans>New invoice created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const deleteCfs = (id) => {
  return (dispatch) => {
    return httpClient
      .del(endpoints.invoices + '/' + id)
      .then((res) => {
        fireSuccessToast(<Trans>Invoice deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Deletion failed.</Trans>)
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const patchCfs = (id, data) => {
  return (dispatch) => {
    return httpClient
      .patch(endpoints.invoices + '/' + id, data)
      .then((res) => {
        fireSuccessToast(<Trans>Invoice updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const getCar = (filters = null) => {
  return (dispatch) => {
    dispatch({type: GET_CARS_REQUEST})
    return httpClient
      .get(endpoints.cars, {
        rz: filters.rz ? filters.rz : null,
        vin: filters.vin ? filters.vin : null,
      })
      .then((res) => {
        dispatch({type: GET_CARS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CARS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getAttachments = () => {
  return (dispatch) => {
    dispatch({type: GET_ATTACHMENTS_REQUEST})
    return httpClient
      .get(endpoints.attachments, {limit: 1000, offset: 0})
      .then((res) => {
        dispatch({type: GET_ATTACHMENTS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_ATTACHMENTS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getMySuppliers = () => {
  return (dispatch) => {
    dispatch({type: GET_MY_SUPPLIERS_REQUEST})
    return httpClient
      .get(endpoints.mySupplier)
      .then((res) => {
        dispatch({type: GET_MY_SUPPLIERS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_MY_SUPPLIERS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getMyFleets = () => {
  return (dispatch) => {
    dispatch({type: GET_MY_FLEETS_REQUEST})
    return httpClient
      .get(endpoints.myFleets, {limit: 1000, offset: 0, orderBy: 'customer:ASC'})
      .then((res) => {
        dispatch({type: GET_MY_FLEETS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_MY_FLEETS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getPredefinedTerms = (fleetId) => {
  return (dispatch) => {
    dispatch({type: GET_PREDEFINED_TERMS_REQUEST})
    return httpClient
      .get(`${endpoints.fleets}/${fleetId}/predefined-terms`)
      .then((res) => {
        dispatch({type: GET_PREDEFINED_TERMS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_PREDEFINED_TERMS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}
