import {combineReducers} from 'redux'
import globalSettingsReducer from 'redux/reducer/globalSettingsReducer'
import authReducer from 'redux/reducer/authReducer'
import cfsReducer from 'redux/reducer/cfsReducer'

export default combineReducers({
  auth: authReducer,
  globalSettings: globalSettingsReducer,
  cfs: cfsReducer,
})
