import React from 'react'
import {Router, Switch, Redirect} from 'react-router-dom'
import browserHistory from 'helper/history'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoginPage from 'page/LoginPage'
import {I18nProvider} from '@lingui/react'
import {i18n} from '@lingui/core'
import catalogEn from 'App/locale/en/messages'
import catalogCs from 'App/locale/cs/messages'
import catalogHu from 'App/locale/hu/messages'
import catalogPl from 'App/locale/pl/messages'
import catalogRo from 'App/locale/ro/messages'
import catalogSk from 'App/locale/sk/messages'
import catalogUk from 'App/locale/uk/messages'
import {connect} from 'react-redux'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import toastStyle from 'component/toast/toastStyle'
import moment from 'moment'
import 'moment/min/locales'
import {en, cs, hu, pl, ro, sk, uk} from 'make-plural'
import PrivateRoute from 'layout/PrivateLayout/PrivateRoute'
import PublicRoute from 'layout/PublicLayout/PublicRoute'
import ResetPasswordPage from 'page/ResetPasswordPage'
import CFSPage from 'page/CFSPage'
import CFSDetail from 'component/CFSPage/CFSDetail'

function App(props) {
  const {globalSettings, classes} = props

  const activeLang = globalSettings.currentLanguage ? globalSettings.currentLanguage : 'cs'

  moment.locale(activeLang)

  i18n.load({
    en: catalogEn.messages,
    cs: catalogCs.messages,
    hu: catalogHu.messages,
    pl: catalogPl.messages,
    ro: catalogRo.messages,
    sk: catalogSk.messages,
    uk: catalogUk.messages,
  })

  i18n.loadLocaleData('en', {plurals: en})
  i18n.loadLocaleData('cs', {plurals: cs})
  i18n.loadLocaleData('hu', {plurals: hu})
  i18n.loadLocaleData('pl', {plurals: pl})
  i18n.loadLocaleData('ro', {plurals: ro})
  i18n.loadLocaleData('sk', {plurals: sk})
  i18n.loadLocaleData('uk', {plurals: uk})

  i18n.activate(activeLang)

  return (
    <I18nProvider i18n={i18n}>
      <ToastContainer
        position="top-right"
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
        newestOnTop
        closeButton={false}
        autoClose={2500}
        className={classes.containerStyle}
      />
      <Router history={browserHistory}>
        <Switch>
          {/* PUBLIC ROUTES */}
          <PublicRoute path="/login" component={LoginPage} />
          <PublicRoute exact path="/reset-password/:token" component={ResetPasswordPage} />

          {/* PRIVATE ROUTES */}
          <PrivateRoute exact path={['/', '/invoice']} component={CFSPage} />
          <PrivateRoute exact path="/invoice/:id" component={CFSDetail} />

          <PrivateRoute path="/" component={CFSPage} />

          <Redirect to="/login" />
        </Switch>
      </Router>
    </I18nProvider>
  )
}

App.propTypes = {
  globalSettings: PropTypes.object,
  classes: PropTypes.object,
}

export default compose(
  withStyles(toastStyle),
  connect((store) => {
    return {
      globalSettings: store.globalSettings,
    }
  })
)(App)
