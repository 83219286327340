import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {Trans} from '@lingui/macro'
import PrivateLayoutTopBar from 'layout/PrivateLayout/PrivateLayoutTopBar'
import {useLocation} from 'react-router-dom'
import {getCurrentUser} from 'redux/action/authAction'
import PrimaryButton from 'component/material/PrimaryButton'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {redirectTo} from 'helper/functions'

const PrivateLayout = (props) => {
  const {classes, children, getCurrentUser, currentUser, currentUserLoading, cfs, cfsLoading} =
    props

  let location = useLocation()

  const isDisabled = cfs?.status !== 3

  const getTitle = () => {
    const detailId = location.pathname?.split('/')?.[2]
    if (detailId === 'new') {
      return (
        <GridContainer justifyContent={'space-between'}>
          <GridItem xs={true}>
            <Trans>New invoice</Trans>
          </GridItem>
          <GridItem container xs={true} spacing={2} justifyContent={'flex-end'}>
            <GridItem>
              <PrimaryButton
                size={'large'}
                variant={'outlined'}
                className={classes.outlinedButton}
                fullWidth={false}
                textPadding={0}
                text={<Trans>Back</Trans>}
                onClick={() => redirectTo('/invoice')}
              />
            </GridItem>
            <GridItem>
              <PrimaryButton
                size={'large'}
                fullWidth={false}
                textPadding={0}
                text={<Trans>Save</Trans>}
                onClick={() => document.getElementById('submit-button').click()}
              />
            </GridItem>
          </GridItem>
        </GridContainer>
      )
    }
    if (!isNaN(Number(detailId))) {
      return (
        <GridContainer justifyContent={'space-between'}>
          <GridItem xs={true}>
            <Trans>Invoice detail</Trans>
          </GridItem>
          <GridItem container xs={true} spacing={2} justifyContent={'flex-end'}>
            {!cfsLoading && (
              <>
                <GridItem>
                  <PrimaryButton
                    size={'large'}
                    className={classes.outlinedButton}
                    variant={'outlined'}
                    fullWidth={false}
                    textPadding={0}
                    text={<Trans>Back</Trans>}
                    onClick={() => redirectTo('/invoice')}
                  />
                </GridItem>
                {!isDisabled && (
                  <GridItem>
                    <PrimaryButton
                      size={'large'}
                      fullWidth={false}
                      textPadding={0}
                      text={<Trans>Save</Trans>}
                      onClick={() => {
                        return document.getElementById('submit-button').click()
                      }}
                    />
                  </GridItem>
                )}
              </>
            )}
          </GridItem>
        </GridContainer>
      )
    }
    return <Trans>Invoice history</Trans>
  }

  useEffect(() => {
    getCurrentUser()
  }, [])

  return (
    <div className={classes.root}>
      <PrivateLayoutTopBar loading={currentUserLoading} currentUser={currentUser} />

      <main className={classes.content}>
        <div className={classes.pageTitle}>{getTitle()}</div>
        {children}
      </main>
    </div>
  )
}

PrivateLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  getCurrentUser: PropTypes.func,
  currentUser: PropTypes.object,
  currentUserLoading: PropTypes.bool,
  cfs: PropTypes.object,
  cfsLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCurrentUser,
    },
    dispatch
  )
}

export default compose(
  withStyles(privateLayoutStyle),
  connect((store) => {
    return {
      currentUser: store.auth.currentUser,
      currentUserLoading: store.auth.currentUserLoading,
      cfs: store.cfs.cfs,
      cfsLoading: store.cfs.cfsLoading,
    }
  }, mapDispatchToProps)
)(PrivateLayout)
